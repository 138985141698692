import { PageProps } from "gatsby";
import React from "react";

import { NoteTrainerApplication } from "../components/pages/NoteTrainerApplication";

import { snProps } from "../js/utils";

const NoteTrainerApplicationPage = (props: PageProps) => (
  <NoteTrainerApplication {...{ ...snProps, ...props }} />
);

export default NoteTrainerApplicationPage;
