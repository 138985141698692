import React from "react";
import { Link } from "gatsby";
import Layout from "../parts/Layout";
import { PageComponentProps } from "../../types/types";

// @ts-ignore
import NoteTrainerMidiAppScreenshot from "../../images/png/note-trainer-midi-app-screenshot.png";

import * as pageStyles from "../global-styles/page.module.css";

export const NoteTrainerApplication = (props: PageComponentProps) => {
  const title = "NoteTrainer";
  const description = `Free application to help you learn to read ${props.clairnoteName} music notation. Works with MIDI keyboards and other MIDI instruments.`;
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <div>
          {props.sn ? (
            <>
              <p>
                Currently the{" "}
                <Link to={props.otherUrlDir + "notetrainer-application/"}>
                  NoteTrainer
                </Link>{" "}
                application is only for{" "}
                <Link to={props.otherUrlDir}>Clairnote DN</Link> and not{" "}
                <Link to={props.urlDir}>Clairnote SN</Link>.
              </p>
            </>
          ) : (
            <>
              <p>
                Learn to read {props.clairnoteName} music notation with this
                simple "NoteTrainer" application. You read the random series of
                notes it displays and play them on a MIDI keyboard. As you play,
                it lets you know if you have played the correct note or not.
                Here's what it looks like:
              </p>
              <div className={pageStyles.imageContainer}>
                <img
                  src={NoteTrainerMidiAppScreenshot}
                  alt="Screenshot of NoteTrainer MIDI App"
                  width="746"
                  height="513"
                />
              </div>
              <p>
                You can customize how many octaves the staff spans and which set
                of notes you want to work on reading. There is also an option to
                show solfege note names next to each note on the staff. The
                application requires a MIDI keyboard or other MIDI instrument or
                device.
              </p>
              <p>
                <strong>
                  Download the application from{" "}
                  <a
                    href="https://github.com/zmeyc/notetrainer/releases"
                    target="_blank"
                    rel="external noopener noreferrer"
                  >
                    this page on Github
                  </a>
                </strong>
              </p>
              <p>
                Precompiled binaries are available for Windows (.exe) and Mac
                (.dmg) or download and build the source code for GNU/Linux.
              </p>
              <p>
                Many thanks to Andrey Fidrya for writing this application for{" "}
                {props.clairnoteName}!
              </p>
              <p>
                No MIDI keyboard? No problem, check out our web-based games on
                the{" "}
                <Link to={props.urlDir + "learn/"} title="Learn">
                  Learn
                </Link>{" "}
                page.
              </p>
            </>
          )}
        </div>
      </article>
    </Layout>
  );
};
